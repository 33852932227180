import Home from '../components/pages/home/Home';
import Store from '../components/pages/store/Store';
import Departments from '../components/pages/departments/Departments';
import Department from '../components/pages/department/Department';
import CustomDepartment from '../components/pages/department/CustomDepartment';
import Search from '../components/pages/search/Search';
import Register from '../components/pages/register/Register';
import Login from '../components/pages/login/Login';
import Checkout from '../components/pages/checkout/Checkout';
import Order from '../components/pages/order/Order';
import NotFound from '../components/pages/404/404';
import Orders from '../components/pages/orders/Orders';
import Profile from '../components/pages/profile/Profile';
import Password from '../components/pages/password/Password';
import Reset from '../components/pages/reset/Reset';
import PayPalPay from '../components/pages/paypal/pay';
import PayPalAccept from '../components/pages/paypal/accept';
import PayPalCancel from '../components/pages/paypal/cancel';
import PayPalAppAccept from '../components/pages/paypal/app_accept';
import PayPalAppCancel from '../components/pages/paypal/app_cancel';
import Terms from '../components/pages/terms/Terms';
import Privacy from '../components/pages/privacy/Privacy';
import AppClose from '../components/pages/app/close';
import PriceCheck from '../components/pages/price-check/PriceCheck';
import UnderMaintenence from '../components/pages/under-maintenence/UnderMaintenence'

const routes = [
  // {
  //   id: 'home',
  //   name: 'Home',
  //   path: '/',
  //   exact: true,
  //   protected: false,
  //   component: Home,
  // },
  // {
  //   id: 'homeold',
  //   name: 'Home',
  //   path: '/home',
  //   exact: true,
  //   protected: false,
  //   component: Home,
  // },
  // {
  //   id: 'store',
  //   name: 'Tienda',
  //   path: '/stores/:id',
  //   exact: true,
  //   protected: false,
  //   component: Store,
  // },
  // {
  //   id: 'departments',
  //   name: 'Departamentos',
  //   path: '/stores/:id/departments',
  //   exact: true,
  //   protected: false,
  //   component: Departments,
  // },
  // {
  //   id: 'department',
  //   name: 'Departamento',
  //   path: '/stores/:id/departments/:departmentID',
  //   exact: false,
  //   protected: false,
  //   component: Department,
  // },
  // {
  //   id: 'department',
  //   name: 'Departamento',
  //   path: '/stores/:id/customdepartments/:searchtype',
  //   exact: false,
  //   protected: false,
  //   component: CustomDepartment,
  // },
  // {
  //   id: 'search',
  //   name: 'Busqueda',
  //   path: '/stores/:id/search',
  //   exact: false,
  //   protected: false,
  //   component: Search,
  // },
  // {
  //   id: 'register',
  //   name: 'Registro',
  //   path: '/register/:friendCode?',
  //   exact: false,
  //   protected: false,
  //   component: Register,
  // },
  // {
  //   id: 'login',
  //   name: 'Iniciar Sesión',
  //   path: '/login',
  //   exact: false,
  //   protected: false,
  //   component: Login,
  // },
  // {
  //   id: 'checkout',
  //   name: 'Pagar',
  //   path: '/checkout',
  //   exact: false,
  //   protected: true,
  //   component: Checkout,
  // },
  // {
  //   id: 'order',
  //   name: 'Orden',
  //   path: '/order/:code',
  //   exact: false,
  //   protected: false,
  //   component: Order,
  // },
  // {
  //   id: 'orders',
  //   name: 'Ordenes',
  //   path: '/orders',
  //   exact: false,
  //   protected: false,
  //   component: Orders,
  // },
  // {
  //   id: 'profile',
  //   name: 'Profile',
  //   path: '/profile/:section?/:order?',
  //   exact: false,
  //   protected: true,
  //   component: Profile,
  // },
  // {
  //   id: 'password',
  //   name: 'Password',
  //   path: '/password',
  //   exact: false,
  //   protected: false,
  //   component: Password,
  // },
  // {
  //   id: 'reset',
  //   name: 'Reset',
  //   path: '/reset/:id/:token',
  //   exact: false,
  //   protected: false,
  //   component: Reset,
  // },
  // {
  //   id: 'paypalpay',
  //   name: 'Paypal Pay',
  //   path: '/paypal/pay/:confirmationCode',
  //   exact: false,
  //   protected: false,
  //   component: PayPalPay,
  // },
  // {
  //   id: 'paypalaccept',
  //   name: 'Paypal Accept',
  //   path: '/paypal/return',
  //   exact: false,
  //   protected: false,
  //   component: PayPalAccept,
  // },
  // {
  //   id: 'paypalappaccept',
  //   name: 'Paypal App Accept',
  //   path: '/paypal/app/return',
  //   exact: false,
  //   protected: false,
  //   component: PayPalAppAccept,
  // },
  // {
  //   id: 'paypalcancel',
  //   name: 'Paypal Cancel',
  //   path: '/paypal/cancel',
  //   exact: false,
  //   protected: false,
  //   component: PayPalCancel,
  // },
  // {
  //   id: 'paypalappcancel',
  //   name: 'Paypal App Cancel',
  //   path: '/paypal/app/cancel',
  //   exact: false,
  //   protected: false,
  //   component: PayPalAppCancel,
  // },
  // {
  //   id: 'privacy',
  //   name: 'Privacy Policy',
  //   path: '/privacy',
  //   exact: false,
  //   protected: false,
  //   component: Privacy,
  // },
  // {
  //   id: 'terms',
  //   name: 'Terms',
  //   path: '/terms',
  //   exact: false,
  //   protected: false,
  //   component: Terms,
  // },
  {
    id: 'pricecheck',
    name: 'Price Check',
    path: '/pricecheck',
    exact: false,
    protected: false,
    component: PriceCheck,
  },
  // {
  //   id: 'appclose',
  //   name: 'App Close',
  //   path: '/appclose',
  //   exact: false,
  //   protected: false,
  //   component: AppClose,
  // },
  // {
  //   id: 'notfound',
  //   name: 'Not Found',
  //   path: '*',
  //   exact: false,
  //   protected: false,
  //   component: NotFound,
  // }
  {
    id: 'undermaintenence',
    name: 'Under Maintenence',
    path: '*',
    exact: false,
    protected: false,
    component: UnderMaintenence,
  }
];

export default routes;
