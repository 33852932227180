import React, { useEffect } from 'react';
import LogRocket from 'logrocket';
import _ from 'lodash';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faShoppingBasket, faLanguage, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory, useParams, NavLink } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useResetRecoilState
} from 'recoil';
import Logo from '../../../assets/img/LaHaciendaLogo.png';
import LeafLogo from '../../../assets/img/lhfleaf.png';
import {
  menuAtom,
  orderAtom,
  paymentAtom,
  serviceAtom,
  sidebarAtom,
  storeAtom,
  tokenAtom,
  userAtom,
} from '../../../atoms/Atoms';
import LoyaltySummary from '../LoyaltySummary';
import User from '../user/User';
import { businessesSelector } from '../../../atoms/Selectors';
import envConfig from '../../../envConfig';

function Header(props) {
  const { i18n, t } = useTranslation();
  const refreshStore = useResetRecoilState(businessesSelector);
  const history = useHistory();
  const { id } = useParams();
  const [user, setUser] = useRecoilState(userAtom);
  const setToken = useSetRecoilState(tokenAtom);
  const setPayment = useSetRecoilState(paymentAtom);
  const order = useRecoilValue(orderAtom);
  const setSidebar = useSetRecoilState(sidebarAtom);
  const store = useRecoilValue(storeAtom);
  const [menu, setMenu] = useRecoilState(menuAtom);
  const service = useRecoilValue(serviceAtom);

  const languages = [    
    {
      key: 'en',
      lang: 'en',
      name: 'English',
    },
    {
      key: 'es',
      lang: 'es',
      name: 'Español',
    },
  ];

  const { location } = props;

  // useEffect(() => {
  //   if (store && store.id !== id) {
  //     if (location && location.pathname !== '/') {
  //       history.push('/');
  //     }
  //   }
  // }, [store, order]);

  // useEffect(() => {
  //   if (user && envConfig.UseLogRocket === 'true') {
  //     LogRocket.identify(user.id, {
  //       name: `${user.firstName} ${user.lastName}`,
  //       email: user.email,
  //     });
  //   }
  // }, [user]);

  return (
    <header>
      <nav className="flex flex-shrink-0 bg-primary border-b-4 items-center justify-between px-4 h-20 text-sm font-semibold">
        <div className="flex items-center space-x-2">
          <Link to="/" className="flex flex-row">
            <img alt="Leaf" src={LeafLogo} className="w-6 md:w-10 h-6 md:h-10" />
            <img alt="Logo" src={Logo} className="w-28 md:w-48 h-auto" />
          </Link>
        </div>
      </nav>
    </header>
  );
}

export default Header;
