import React from 'react';
import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router';
import { motion } from 'framer-motion';
import { useUnmount } from 'react-use';
import { useSetRecoilState } from 'recoil';
import Animation from '../../shared-components/Animation/Animation';
import goodieslogo from '../../../assets/img/bag.png';
// import Content from './shared-components/content/Content';
import { storesDialogAtom } from '../../../atoms/Atoms';



function UnderMaintenence() {
  const { t } = useTranslation();
  const setDialog = useSetRecoilState(storesDialogAtom);

  useUnmount(() => {
    setDialog(false);
  });

  return (
    <Animation className="flex flex-col flex-1 justify-between">
      <div className="grid grid-cols-1 md:grid-cols-2 flex flex-1 bg-bottom bg-cover bg-bag md:bg-bagVer">
        <div className="flex flex-col flex-1 justify-between p-4">
          <div className="flex flex-1 justify-start md:justify-center text-center flex-col">
            <div className="mx-auto hidden">
              <img src={goodieslogo} alt="Goodies for Foodies" className="h-full w-auto" />
            </div>
            <div className="hidden text-md md:text-xl font-light text-gray-500 tracking-tight mb-4">
              {t('experience')}
            </div>       
            <div className="flex flex-1 justify-center items-center flex-col -mt-24">
              <div className="text-4xl md:text-7xl tracking-tight font-extrabold text-red-400">
                {t('under_maintenance')}
              </div>
              <div className="text-xl md:text-4xl text-secondary tracking-tight font-extrabold">
                {t('under_maintenance_prompt')}
              </div>
            </div>
          </div>
        </div>
        <section className="hidden md:flex flex-1 flex-shrink-0">
          <div className="flex flex-col flex-1 justify-between">
            <div>
              <img 
                hidden
                src={goodieslogo}
                alt="Goodies Logo"
                className="h-7 w-auto object-none object-center"
              />
            </div>
          </div>
        </section>
      </div>
    </Animation>
  );
}

export default UnderMaintenence;
