const envConfig = {
  ApiUrl: process.env.REACT_APP_API_URL ?? 'https://lhf.azure-api.net/webdev/',
  ApiKey: process.env.REACT_APP_API_KEY ?? '652e8067f05d41af988ad4b7681109c0',
  HubUrl: process.env.REACT_APP_HUB_URL ?? 'https://lhf-dev-api.azurewebsites.net/',
  MapboxToken: process.env.REACT_APP_MAPBOX_TOKEN ?? '',
  PaypalClientId: process.env.REACT_APP_PAYPAL_CLIENTID ?? 'AfjtD0uNdX6314-yKYV8k6gKy6AdQlUGZRK8LsGvs_e8EBbf-gdgSBZ40ogZLgnHB0bD8rlXP__oUeae',
  GeoApiKey: process.env.REACT_APP_GEO_API_KEY ?? 'AIzaSyB6jl09IPMyXwc55wjIj40Mi9p2ABtZKf0',
  SearchItemsPageSize: 8,
  DepartmentCarouselSize: 6,
  UseLogRocket: process.env.REACT_APP_UseLogRocket ?? 'false',
  TosUrl: process.env.REACT_APP_TOS_URL ?? 'https://lahaciendafoods.shop/terms'
};

export default envConfig;
