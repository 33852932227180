import React from 'react';
import _ from 'lodash';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Banner1 from '../../../../../assets/img/services/services_pickup.png';
import Banner2 from '../../../../../assets/img/services/services_delivery.png';
import Banner3 from '../../../../../assets/img/services/services_offers.png';
import Service from './shared-components/Service';
import {
  locationAtom,
  storesDialogAtom,
} from '../../../../../atoms/Atoms';

function Services() {
  const setOpen = useSetRecoilState(storesDialogAtom);
  const [location, setLocation] = useRecoilState(locationAtom);

  const services = [
    {
      id: '1',
      title: 'Compra Express',
      body: '¡Seleccionamos tus productos!',
      note: 'Tiempo aproximado de 45 minutos',
      image: Banner1,
      buttonText: 'Haz tu Pedido Para Recoger',
      onClick: () => setOpen(true),
      className: 'bg-yellow-400'
    },
    {
      id: '2',
      title: '¡Haz tu compra hoy y nosotros te enviamos los paquetes!',
      body: 'Elige el horario de tu conveniencia, nuestro personal seleccionará tus productos y luego los entregaran al lugar de preferencia.',
      note: '',
      buttonText: 'Solicta Entrega a Domicilio',
      // buttonText: '¡Disponible pronto!',
      image: Banner2,
      onClick: () => {
        setLocation({
          ...location,
          modal: true,
          page: 0,
          current: 'locations',
        })
      }
    },
    // {
    //   id: '3',
    //   title: '¡Los mejores precios y punto!',
    //   body: 'Aquí encontrarás la mejor calidad y variedad.',
    //   note: '',
    //   image: Banner3,
    // },
  ];

  return (
    <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-4 py-12 px-4">
      {_.map(
        services,
        ({ id, title, body, note, image, buttonText, onClick }) => (
          <Service
            key={id}
            title={title}
            body={body}
            note={note}
            image={image}
            buttonText={buttonText}
            onClick={onClick}
          />
        )
      )}
    </div>
  );
}

export default Services;
